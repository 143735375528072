import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  isTouchedAndValid(form: FormGroup, controlName: string): boolean {
    const control = form.get(controlName);
    return !!control && control.touched && control.valid;
  }

  isTouchedAndInvalid(form: FormGroup, controlName: string): boolean {
    const control = form.get(controlName);
    return !!control && control.touched && control.invalid;
  }
}
