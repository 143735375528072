import { Component, EventEmitter, Output } from '@angular/core';
import { MancoFilters } from './manco-filter.pipe';

@Component({
  selector: 'app-manco-filter',
  templateUrl: './manco-filter.component.html',
  styleUrls: ['./manco-filter.component.scss'],
})
export class MancoFilterComponent {
  possibleFilters = Object.values(MancoFilters);
  selectedFilter: MancoFilters = MancoFilters.AllMancos;
  filterOnReturnRegisteredAwaitingDelivery: boolean = false;

  @Output() selectedFilterChange = new EventEmitter<MancoFilters>();
  @Output() returnRegisteredAwaitingDeliveryFilterChange =
    new EventEmitter<boolean>();

  onSelectedFilterChange(): void {
    this.selectedFilterChange.emit(this.selectedFilter);
  }

  onReturnRegisteredAwaitingDeliveryFilterChange(): void {
    this.returnRegisteredAwaitingDeliveryFilterChange.emit(
      this.filterOnReturnRegisteredAwaitingDelivery
    );
  }
}
