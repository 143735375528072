import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  getMancoTypeLabel,
  MancoOrderResponse,
  MancoType,
} from '../manco-order-request';

@Component({
  selector: 'app-manco-order-summary',
  templateUrl: './manco-order-summary.component.html',
  styleUrl: './manco-order-summary.component.scss',
})
export class MancoOrderSummaryComponent {
  @Input() response!: MancoOrderResponse;

  @Output('closeSummary')
  closeSummaryEmitter = new EventEmitter<any>();

  getMancoTypeLabel(value: MancoType): string {
    return getMancoTypeLabel(value);
  }

  emitClose(): void {
    this.closeSummaryEmitter.emit();
  }
}
