<h1 class="text-center border-bottom pb-3">
  SOS-mail Orders van gisteren zonder response
</h1>
<div
  class="row"
  [ngClass]="{ 'd-none': feedbackSettings.show || sosCustomers.length > 0 }"
>
  <div class="w-65 p-3 col-md-8 offset-md-2">
    <p class="mb-0">
      Door op de knop 'Ophalen' te drukken zoekt het systeem uit welke orders
      van gisteren die tussen 18:00 en 18:30 zijn binnen gekomen en die niet
      voor 19:30 een bevestiging (response) van Kramp gehad hebben.
    </p>
    <p class="mb-2">
      <b>Let op:</b> Na 13:00 kijkt de 'Ophalen' knop naar orders van vandaag.
      Dit om Kramp beter in de gaten te kunnen houden.
      <i
        >(Dit gedrag kan beïnvloed worden door de checkbox hier onder te
        veranderen.)</i
      >
    </p>
    <p class="mb-2">
      Als extra service krijgt u ook een
      <a href="https://en.wikipedia.org/wiki/Mailto" target="_blank"
        >mailto-link</a
      >
      om alle klanten die getroffen zijn een bericht te sturen.
    </p>
    <div class="d-flex flex-column">
      <div class="form-group pt-1">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="yesterdayCheckbox"
          [(ngModel)]="isYesterdayChecked"
        />
        <label class="form-check-label mx-2" for="yesterdayCheckbox">
          Gisteren
        </label>
      </div>
      <div class="form-group align-self-end pt-1">
        <button
          class="btn btn-primary"
          type="submit"
          (click)="getSOSCustomers()"
        >
          Opvragen
        </button>
      </div>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center my-5">
  <app-feedback [(settings)]="feedbackSettings"></app-feedback>
</div>
<div [ngClass]="{ 'd-none': sosCustomers.length <= 0 }">
  <div class="d-flex flex-column">
    <div class="form-group align-self-end pb-2">
      <button class="btn btn-primary" type="submit" (click)="allowQueryAgain()">
        Nieuwe query
      </button>
    </div>
    <app-sos-customer-list
      [didSearch]="didSearch"
      [sosCustomers]="sosCustomers"
    ></app-sos-customer-list>
  </div>
</div>

<div [ngClass]="{ 'd-none': !didSearch || sosCustomers.length > 0 }">
  <div class="d-flex justify-content-center">
    <div class="p-5 border d-flex align-items-center">
      <i class="fa-solid fa-clipboard-list me-3 primary-color fa-2xl"></i>
      <span class="fs-4"> Geen orders zonder response gevonden! </span>
    </div>
  </div>
</div>
<div [ngClass]="{ 'd-none': !isLoading }">
  <app-loading-gears></app-loading-gears>
</div>
