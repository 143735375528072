import { Inject, Pipe, PipeTransform } from '@angular/core';
import { FRESHDESK_SERVICE_URL } from 'src/app/injection-tokens';

@Pipe({
  name: 'freshdeskOmsOrderLink',
})
export class FreshdeskOmsOrderLinkPipe implements PipeTransform {
  constructor(
    @Inject(FRESHDESK_SERVICE_URL) readonly baseFreshdeskUrl: string
  ) {}

  transform(orderNumber: string, ...args: unknown[]): unknown {
    return `${this.baseFreshdeskUrl}/iom/redirect/order?shopOrderNumber=${orderNumber}`;
  }
}
