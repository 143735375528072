import { DeliveryAddress } from './order-details';

export interface MancoOrderRequest {
  forOrderNumber: string;
  forCustomerNumber: string;
  mancoType: MancoType;
  freshdeskTicketNumber?: string;
  ishShippingMethodID: string;
  deliveryAddress: APIAddress;
  orderlines: MancoOrderLine[];
  note?: string;
  customerRef?: string;
  createdByHint?: string;
}

export interface MancoOrderLine {
  rowNumber: number; // row number of manco
  lineIDSource?: string; //original line position
  sku: string;
  nameSource?: string;
  quantitySource: number;
  orderlineRef?: string;
  properties?: Map<string, string>;
}
export interface APIAddress extends DeliveryAddress {
  company: string;
  email: string;
}

export enum MancoType {
  Select = 'select',
  MancoCarrier = 'mancoCarrier',
  MancoSupplier = 'mancoSupplier',
  IncorrectRedelivery = 'incorrectRedelivery',
  DamageRedelivery = 'damageRedelivery',
  Warranty = 'warranty',
  SalesCosts = 'salesCosts',
}

export interface MancoOrderResponse {
  orderNumber: string;
  freshdeskNoteCreated: boolean;
  request?: MancoOrderRequest;
}

export const MancoTypeOptions = [
  //comment = customerNo of mancoAccount
  { value: MancoType.Select, label: 'Selecteer reden' }, // 3390003
  { value: MancoType.MancoCarrier, label: 'Manco vervoerder' }, // 3390003
  { value: MancoType.MancoSupplier, label: 'Manco kramp' }, // 3390013
  { value: MancoType.IncorrectRedelivery, label: 'Foutieve herlevering' }, // 3390004
  { value: MancoType.DamageRedelivery, label: 'Schade herlevering' }, // 3390001
  { value: MancoType.Warranty, label: 'Garantie' }, // 3390005
  { value: MancoType.SalesCosts, label: 'Verkoopkosten' }, // 3390002
];

export function getMancoTypeLabel(value: MancoType): string {
  const option = MancoTypeOptions.find((option) => option.value === value);
  return option ? option.label : '';
}
