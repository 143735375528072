<div class="radio-group d-flex flex-row">
  <label *ngFor="let filter of possibleFilters" class="me-3">
    <input
      type="radio"
      class="form-check-input"
      name="options"
      [value]="filter"
      [(ngModel)]="selectedFilter"
      (change)="onSelectedFilterChange()"
    />
    {{ filter }}
  </label>
</div>

<input
  type="checkbox"
  class="form-check-input me-2"
  id="doubleReturnsCheckbox"
  [(ngModel)]="filterOnReturnRegisteredAwaitingDelivery"
  (change)="onReturnRegisteredAwaitingDeliveryFilterChange()"
/>
<label for="doubleReturnsCheckbox" class="form-check-label">
  Aangemelde retour in afwachting van ontvangst
</label>
