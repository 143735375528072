import { Pipe, PipeTransform } from '@angular/core';
import { MancoToCheckUpOn } from './manco-to-check-up-on';

@Pipe({
  name: 'mancosToCheckUpOnSearch',
})
export class MancosToCheckUpOnSearchPipe implements PipeTransform {
  transform(items: MancoToCheckUpOn[], searchText: string): MancoToCheckUpOn[] {
    if (!items) return [];

    if (!searchText) return items;
    searchText = searchText.toLowerCase();

    return items.filter((manco) => {
      return (
        manco.caseNumber?.toLowerCase().includes(searchText) ||
        manco.customerNumber?.toLowerCase().includes(searchText) ||
        manco.ticketNumber?.toLowerCase().includes(searchText) ||
        manco.note?.toLowerCase().includes(searchText) ||
        manco.returnNumber?.toLowerCase().includes(searchText) ||
        manco.treatmentDate.toLowerCase().includes(searchText) ||
        manco.checkDate.toLowerCase().includes(searchText) ||
        manco.createdBy?.toLowerCase().includes(searchText) ||
        manco.updatedBy?.toLowerCase().includes(searchText)
      );
    });
  }
}
