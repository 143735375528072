import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { BASE_INDI_URL } from 'src/app/injection-tokens';
import { OrderDetails, Orderline } from '../order-details';
import {
  MancoType,
  MancoTypeOptions,
  MancoOrderRequest,
} from '../manco-order-request';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  mancoTypeValidator,
  orderlinesValidator,
  quantityRangeValidator,
} from './manco-request-validators';

@Component({
  selector: 'app-new-manco-orderlines-table',
  templateUrl: './new-manco-orderlines-table.component.html',
  styleUrls: ['./new-manco-orderlines-table.component.scss'],
})
export class NewMancoOrderlinesTableComponent {
  @Input() orderDetails!: OrderDetails;

  @Output('submitMancoRequest')
  submitMancoRequest: EventEmitter<MancoOrderRequest> = new EventEmitter();
  @ViewChildren('quantitySourceInput') inputElements?: QueryList<ElementRef>;

  mancoTypeOptions = MancoTypeOptions;
  form: FormGroup;
  selectedOrderlines: Map<number, FormGroup> = new Map();

  constructor(
    @Inject(BASE_INDI_URL) readonly baseIndiUrl: string,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      mancoType: [
        MancoType.Select,
        [Validators.required, mancoTypeValidator()],
      ],
      customerRef: [''],
      note: [''],
      deliveryAddress: this.fb.group({
        company: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        address: ['', Validators.required],
        postalCode: ['', Validators.required],
        city: ['', Validators.required],
        country: ['', [Validators.required, Validators.pattern(/^(NL|DE)$/)]],
        email: ['', [Validators.required, Validators.email]],
        telephoneNumber: ['', Validators.required],
        switchToDayShipping: [false],
      }),
      orderlines: this.fb.array([], { validators: orderlinesValidator() }),
      ishShippingMethodID: [''],
    });
  }

  ngOnChanges(): void {
    if (this.orderDetails) {
      this.populateFormWithOrderDetails();
    }
  }

  populateFormWithOrderDetails(): void {
    const deliveryAddress = this.orderDetails.deliveryAddress || {};

    this.form.patchValue({
      ishShippingMethodID: this.orderDetails.shippingMethod,
      mancoType: MancoType.Select,
      customerRef: this.orderDetails.reference,
      note: '',
      deliveryAddress: {
        company: this.orderDetails.customerDetails.companyName || '',
        firstName: deliveryAddress.firstName || '',
        lastName: deliveryAddress.lastName || '',
        address: deliveryAddress.address || '',
        postalCode: deliveryAddress.postalCode || '',
        city: deliveryAddress.city || '',
        country: deliveryAddress.country || '',
        email: deliveryAddress.emailAddress || '',
        telephoneNumber: deliveryAddress.telephoneNumber || '',
      },
    });
  }

  isNightShippingMethod(): boolean {
    return this.orderDetails.shippingMethod
      .toLowerCase()
      .startsWith('shipping-nt');
  }

  onOrderlineCheckboxChange(
    orderline: Orderline,
    event: Event,
    index: number
  ): void {
    const checkbox = event.target as HTMLInputElement;

    if (checkbox.checked) {
      const newOrderline = this.fb.group({
        lineIDSource: [String(index + 1)],
        sku: [orderline.item.sku],
        nameSource: [orderline.item.description],
        quantitySource: [
          null,
          [
            Validators.required,
            quantityRangeValidator(
              orderline.quantityOrdered,
              orderline.quantityReturned
            ),
          ],
        ],
        quantityOrdered: [orderline.quantityOrdered],
        quantityReturned: [orderline.quantityReturned],
      });

      (this.form.get('orderlines') as FormArray).push(newOrderline);

      setTimeout(() => {
        if (this.inputElements) {
          //these always exit when this method is called, the if is here for testing
          const inputToFocus = this.inputElements.toArray()[index];
          if (inputToFocus) {
            inputToFocus.nativeElement.focus();
          }
        }
      });
      this.selectedOrderlines.set(index, newOrderline);
      newOrderline.get('quantitySource')?.enable();
    } else {
      const orderlinesArray = this.form.get('orderlines') as FormArray;
      const formGroupIndex = Array.from(this.selectedOrderlines.keys()).indexOf(
        index
      );

      if (formGroupIndex !== -1) {
        orderlinesArray.removeAt(formGroupIndex);
        this.selectedOrderlines.delete(index);
      }
    }
  }

  onQuantityChange(index: number, event: Event): void {
    const quantitySource = Number((event.target as HTMLInputElement).value);

    const orderlineGroup = this.selectedOrderlines.get(index);

    if (orderlineGroup) {
      const quantityControl = orderlineGroup.get('quantitySource');
      quantityControl?.setValue(quantitySource);
      quantityControl?.markAsTouched();
      quantityControl?.updateValueAndValidity();
    }
  }

  onSwitchToDayShippingChange(): void {
    const isChecked = this.form.get(
      'deliveryAddress.switchToDayShipping'
    )?.value;

    this.form.patchValue({
      ishShippingMethodID: isChecked
        ? 'shipping-standard-free'
        : this.orderDetails.shippingMethod,
    });
  }

  onSubmit(): void {
    const selectedOrderlineValues = Array.from(
      this.selectedOrderlines.values()
    ).map((formGroup, index) => {
      const rawValue = formGroup.getRawValue();

      const { quantityOrdered, quantityReturned, ...sanitizedOrderline } =
        rawValue;

      return {
        ...sanitizedOrderline,
        rowNumber: index + 1,
      };
    });
    const { switchToDayShipping, ...sanitizedDeliveryAddress } =
      this.form.value.deliveryAddress;

    const newMancoRequest: MancoOrderRequest = {
      ...this.form.value,
      forCustomerNumber: this.orderDetails.customerDetails.customerNo,
      forOrderNumber: this.orderDetails.orderNumber,
      customerRef: this.orderDetails.reference,
      deliveryAddress: {
        ...sanitizedDeliveryAddress,
      },
      orderlines: selectedOrderlineValues,
    };

    this.submitMancoRequest.emit(newMancoRequest);
  }

  cannotReorder(orderline: Orderline): boolean {
    return (
      orderline.deliveryStatus.quantityInProcess === 0 &&
      orderline.quantityOrdered ===
        orderline.quantityCancelled + orderline.quantityReturned
    );
  }

  resetForm() {
    this.form.reset();
  }
}
