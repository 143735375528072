<div>
  <div class="mb-2">
    <div class="row pb-1 border-bottom">
      <div class="col col-sm-6">
        <strong>Ordernummer:</strong>
      </div>
      <div class="col col-sm-6">
        <a
          href="{{ orderDetails.orderNumber! | freshdeskOmsOrderLink }}"
          target="_blank"
          >{{ orderDetails.orderNumber }}
        </a>
      </div>
    </div>
    <div class="row pb-1 border-bottom">
      <div class="col col-sm-6">
        <strong>Orderdatum:</strong>
      </div>
      <div class="col col-sm-6">
        {{ orderDetails.orderDate | date : "dd MMMM yyyy" }}
      </div>
    </div>
    <div class="row pb-1 border-bottom">
      <div class="col col-sm-6">
        <strong>Verzendmethode:</strong>
      </div>
      <div class="col col-sm-6">
        {{ orderDetails.shippingMethod }}
      </div>
    </div>
    <div class="row pb-1 border-bottom">
      <div class="col col-sm-6">
        <strong>Orderstatus:</strong>
      </div>
      <div class="col col-sm-6">
        {{ orderDetails.status }}
      </div>
    </div>
    <div class="row pb-1 border-bottom">
      <div class="col col-sm-6">
        <strong>Klantnummer:</strong>
      </div>
      <div class="col col-sm-6">
        <a
          href="{{
            freshdeskServiceUrl +
              'icm/redirect/customer?customerNo=' +
              orderDetails.customerDetails.customerNo
          }}"
          target="_blank"
          >{{ orderDetails.customerDetails.customerNo }}
        </a>
      </div>
    </div>
    <div class="row pb-1">
      <div class="col col-sm-6">
        <strong>Orderreferentie:</strong>
      </div>
      <div class="col col-sm-6">
        {{ orderDetails.reference }}
      </div>
    </div>
  </div>

  <!-- TODO Link to pages -->
  <button class="btn btn-outline-primary me-2 mb-2" disabled>
    <i class="fa-regular fa-truck-fast fa-l"></i>
    Track & Trace
  </button>
  <button class="btn btn-outline-primary me-2 mb-2" disabled>
    <i class="fa-regular fa-barcode fa-l"></i>
    KRAMP KNB
  </button>
  <a href="{{ freshdeskTicketNumber! | freshdeskTicketLink }}" target="_blank">
    <button class="btn btn-outline-primary mb-2">
      <i class="fa-regular fa-ticket fa-l"></i>
      Freshdesk Ticket
    </button>
  </a>
</div>
