import { Component, ViewChild } from '@angular/core';
import { FeedbackComponent } from 'src/app/general-components/feedback/feedback.component';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { NewMancoService } from './new-manco.service';
import { OrderDetails } from './order-details';
import { MancoOrderResponse, MancoOrderRequest } from './manco-order-request';
import { HttpErrorResponse } from '@angular/common/http';
import { NewMancoFormComponent } from './new-manco-form/new-manco-form.component';
import { Profile } from 'src/app/general-components/profile/profile';
import { AuthService } from 'src/app/auth/auth.service';
import { NewMancoOrderlinesTableComponent } from './new-manco-orderlines-table/new-manco-orderlines-table.component';

@Component({
  selector: 'app-new-manco',
  templateUrl: './new-manco.component.html',
  styleUrls: ['./new-manco.component.scss'],
})
export class NewMancoComponent {
  orderNumber: string = '';
  freshdeskTicketNumber: string = '';
  orderDetails?: OrderDetails;
  mancoOrderResponse?: MancoOrderResponse;
  mancoOrderError?: HttpErrorResponse;
  profile: Profile | undefined;

  @ViewChild(FeedbackComponent) feedbackComponent!: FeedbackComponent;
  @ViewChild(NewMancoFormComponent) formComponent!: NewMancoFormComponent;
  @ViewChild(NewMancoOrderlinesTableComponent)
  newMancoOrderlinesTableComponent!: NewMancoOrderlinesTableComponent;

  isLoadingSeach: boolean = false;
  isLoadingResponse: boolean = false;

  constructor(
    private service: NewMancoService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.profile = this.authService.getUserInfo();
  }

  handleSearchSubmit(): void {
    this.isLoadingSeach = true;
    this.mancoOrderError = undefined;
    this.mancoOrderResponse = undefined;

    this.service.getOrderDetails(this.orderNumber).subscribe(
      (orderlines) => {
        this.orderDetails = orderlines;
        this.isLoadingSeach = false;
      },
      (error) => {
        console.error(
          'Failed to look up orderlines for order: ' + this.orderNumber,
          error
        );
        this.feedbackComponent.showFeedback(
          '',
          FeedbackType.error,
          'We konden de orderregels niet ophalen.',
          undefined,
          false
        );
        this.isLoadingSeach = false;
        this.fadeOutFeedback();
      }
    );
  }

  handleSearchReset(): void {
    this.orderNumber = '';
    this.freshdeskTicketNumber = '';
    this.orderDetails = undefined;
    this.mancoOrderError = undefined;
    this.mancoOrderResponse = undefined;
  }

  handleMancoRequestSubmit(mancoRequest: MancoOrderRequest): void {
    mancoRequest.freshdeskTicketNumber = this.freshdeskTicketNumber;
    mancoRequest.createdByHint =
      this.profile?.firstName + ' ' + this.profile?.lastName;

    this.mancoOrderError = undefined;
    this.isLoadingResponse = true;
    this.service.createMancoOrder(mancoRequest).subscribe({
      next: (mancoOrderResponse: MancoOrderResponse) => {
        mancoOrderResponse.request = mancoRequest;
        this.isLoadingResponse = false;

        this.newMancoOrderlinesTableComponent.resetForm();
        this.formComponent.forceReset();
        this.mancoOrderResponse = mancoOrderResponse;
      },
      error: (error: HttpErrorResponse) => {
        console.log(
          'something went wrong with the manco order request: ' + error
        );

        this.isLoadingResponse = false;
        this.mancoOrderError = error;
      },
    });
  }

  fadeOutFeedback(): void {
    setTimeout(() => {
      this.feedbackComponent.fadeOut();
    }, 3000);
  }

  hideOrderSummary(): void {
    this.mancoOrderResponse = undefined;
  }

  hideOrderError(): void {
    this.mancoOrderError = undefined;
  }
}
