import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { OrderLineError } from './errors';

@Component({
  selector: 'app-manco-order-errors',
  templateUrl: './manco-order-errors.component.html',
  styleUrl: './manco-order-errors.component.scss',
})
export class MancoOrderErrorsComponent {
  @Input() error!: HttpErrorResponse;

  @Output('closeError')
  closeErrorEmitter = new EventEmitter<any>();

  errorType:
    | 'validation'
    | 'badRequest'
    | 'internalServerError'
    | 'generic'
    | '' = '';
  validationErrors: Record<string, string[]> = {};
  orderlineErrors: OrderLineError[] = [];
  validationKeys: string[] = [];
  parameterViolations: { path: string; message: string }[] = [];

  ngOnChanges(): void {
    this.categorizeError();
  }

  categorizeError(): void {
    const status = this.error?.status;

    switch (status) {
      case 422:
        this.handleValidationError();
        break;
      case 400:
        this.handleBadRequestError();
        break;
      case 500:
        this.errorType = 'internalServerError';
        break;
      default:
        this.errorType = 'generic';
    }
  }

  handleValidationError(): void {
    this.errorType = 'validation';
    const validationErrors = this.error?.error?.errors ?? {};
    this.validationKeys = Object.keys(validationErrors);
    this.validationErrors = validationErrors;
    this.orderlineErrors = this.parseOrderlineErrors(validationErrors);
  }

  handleBadRequestError(): void {
    this.errorType = 'badRequest';
    this.parameterViolations = this.error?.error?.parameterViolations ?? [];
  }

  errorTitles: Record<string, string> = {
    validation: 'Er klopte iets niet met de order of orderline(s).',
    badRequest: 'De aanvraag bevat fouten.',
    internalServerError: 'Er is iets anders fout gegaan.',
    generic: 'Er is iets anders fout gegaan.',
  };

  getErrorTitle(): string {
    return this.errorTitles[this.errorType];
  }

  getPathTranslation(path: string): string {
    switch (path) {
      case 'createOrder.order.forCustomerNumber':
        return 'Klantnummer';
      case 'createOrder.order.forOrderNumber':
        return 'Originele ordernummer';
      case 'createOrder.order.freshdeskTicketNumber':
        return 'Freshdesk ticket.';
      default:
        return path;
    }
  }

  parseOrderlineErrors(errorObject: {
    [key: string]: string[];
  }): OrderLineError[] {
    return Object.entries(errorObject)
      .filter(([_, messages]) => messages.length > 0)
      .map(([key, messages]) => {
        const orderline = parseInt(key, 10);
        const [primaryMessage, detailsRaw] = messages;

        let details = '';
        let conditions: string[] = [];

        if (detailsRaw) {
          const conditionMatch = detailsRaw.match(/<ul>(.*?)<\/ul>/s);
          if (conditionMatch) {
            const conditionsRaw = conditionMatch[1];
            conditions = conditionsRaw
              .split(/<\/li>/)
              .map((item) => item.replace(/<li>/g, '').trim())
              .filter((item) => item);

            details = detailsRaw.split('<ul>')[0].trim();
          } else {
            details = detailsRaw;
          }
        }

        return { orderline, message: primaryMessage, details, conditions };
      });
  }

  emitClose(): void {
    this.closeErrorEmitter.emit();
  }
}
