import {
  AbstractControl,
  FormArray,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { MancoType } from '../manco-order-request';

export function mancoTypeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === MancoType.Select) {
      return { invalidMancoType: true };
    }
    return null;
  };
}

export function stringLengthValidator(min: number, max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value || '';
    if (value.length < min || value.length > max) {
      return { invalidStringLength: true, min, max };
    }
    return null;
  };
}

export function quantityValidator(orderline: {
  quantityDelivered: number;
  quantityReturned: number;
}): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const quantity = control.value;
    const min = 0.01;
    const max = orderline.quantityDelivered - orderline.quantityReturned;

    if (quantity < min || quantity > max) {
      return { invalidQuantity: { min, max } };
    }
    return null;
  };
}

export function quantityRangeValidator(
  deliveredQuantity: number,
  returnedQuantity: number
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const quantity = control.value;

    if (
      !quantity ||
      isNaN(quantity) ||
      quantity < 0.01 ||
      quantity > deliveredQuantity - returnedQuantity
    ) {
      return { invalidQuantity: true };
    }
    return null;
  };
}

export function orderlinesValidator(): ValidatorFn {
  return (formArray: AbstractControl) => {
    const valid = (formArray as FormArray).controls.some(
      (group) => group.valid
    );
    return valid ? null : { noValidOrderlines: true };
  };
}
