import { Component, Input } from '@angular/core';
import { Orderline } from '../../order-details';
import { AtpService } from 'src/app/general-services/atp/atp.service';
import {
  AtpRequestItem,
  AtpResponseItem,
} from 'src/app/general-services/atp/atp-interfaces';

@Component({
  selector: 'app-new-manco-atp-info',
  templateUrl: './new-manco-atp-info.component.html',
  styleUrl: './new-manco-atp-info.component.scss',
})
export class NewMancoAtpInfoComponent {
  @Input() orderline!: Orderline;
  @Input() inputQuantity?: number;

  isLoading: boolean = false;
  errorOccurred: boolean = false;
  atpResponseItem: AtpResponseItem | null = null;
  iconColor: string = 'primary-color';

  constructor(private service: AtpService) {}

  setIconColor(color: string): void {
    this.iconColor = color;
  }

  getAtpInfo() {
    this.isLoading = true;

    const quantity =
      this.inputQuantity != null
        ? this.inputQuantity
        : this.orderline.quantityOrdered -
          (this.orderline.quantityCancelled + this.orderline.quantityReturned);

    const requestItem: AtpRequestItem = {
      sku: this.orderline.item.sku,
      quantity: quantity.toString(),
    };

    this.service.getSingleAtpInfo(requestItem).subscribe(
      (atpResponseItem: AtpResponseItem) => {
        this.atpResponseItem = atpResponseItem;
        this.errorOccurred = false;
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.errorOccurred = true;
        this.isLoading = false;
      }
    );
  }
}
