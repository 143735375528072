import { addDays, format } from 'date-fns';
import { Pipe, PipeTransform } from '@angular/core';
import { Manco, MancoToCheckUpOn } from '../manco-to-check-up-on';

export enum MancoFilters {
  AllMancos = "Alle Manco's",
  MancosNext3Days = "Manco's voor komende 3 dagen",
}

@Pipe({
  name: 'mancoFilter',
})
export class MancoFilterPipe implements PipeTransform {
  transform(
    mancos: MancoToCheckUpOn[],
    selectedFilter: MancoFilters,
    returnRegisteredAwaitingDeliveryFilter: boolean
  ): MancoToCheckUpOn[] {
    const newDate = addDays(this.today(), 4);
    const checkDate = format(newDate, 'yyyy-MM-dd');

    return mancos.filter((manco: Manco) => {
      // Base filter: Apply selectedFilter logic
      const baseFilter =
        selectedFilter === MancoFilters.AllMancos ||
        (!manco.completed && manco.checkDate <= checkDate);

      // Returns filter: Apply only if returnRegisteredAwaitingDeliveryFilter is true
      const returnsFilter =
        !returnRegisteredAwaitingDeliveryFilter ||
        (!!manco.returnNumber && !manco.returnReceived);

      return baseFilter && returnsFilter;
    });
  }

  today(): Date {
    return new Date();
  }
}
