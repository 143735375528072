import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-custom-icon',
  template: `
    <span class="icon-wrapper" [ngClass]="combinedClasses">
      <span [innerHTML]="svgIcon"></span>
    </span>
  `,
  styleUrls: ['./custom-icon.component.scss'],
})
export class CustomIconComponent {
  @Input() name?: string;
  @Input() classes: string = '';

  public svgIcon: string | SafeHtml = '';

  get combinedClasses(): string[] {
    return this.classes.split(' ').filter((cls) => cls.trim() !== '');
  }

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnChanges(): void {
    if (!this.name) {
      this.svgIcon = '';
      return;
    }
    this.setSvg();
  }

  setSvg() {
    this.httpClient
      .get(`assets/icons/${this.name}.svg`, { responseType: 'text' })
      .subscribe(
        (value: string) => {
          this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value);
        },
        (error) => {
          console.log(error);
          this.svgIcon = '';
        }
      );
  }
}
