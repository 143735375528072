import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AtpRequest, AtpRequestItem, AtpResponseItem } from './atp-interfaces';
import { ATP_SERVICE_URL } from 'src/app/injection-tokens';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AtpService {
  constructor(
    @Inject(ATP_SERVICE_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  getAtpInfo(atpRequest: AtpRequest): Observable<AtpResponseItem[]> {
    return this.http.post<AtpResponseItem[]>(this.serviceURL, atpRequest);
  }

  getSingleAtpInfo(
    atpRequestItem: AtpRequestItem
  ): Observable<AtpResponseItem> {
    const atpRequest: AtpRequest = {
      items: [atpRequestItem],
    };

    return this.getAtpInfo(atpRequest).pipe(
      map((response: AtpResponseItem[]) => {
        if (response.length === 0) {
          throw new Error(
            'No response received for the provided request item.'
          );
        }
        return response[0];
      })
    );
  }
}
