<div class="d-flex justify-content-center my-4">
  <div class="card w-75 position-relative border-danger">
    <button
      type="button"
      class="btn-close position-absolute top-0 end-0 m-3"
      aria-label="Close"
      (click)="emitClose()"
    ></button>
    <div class="card-body">
      <h3 class="card-title mb-">
        <i class="fa-solid fa-triangle-exclamation text-danger me-3"></i>
        <strong>{{ getErrorTitle() }}</strong>
      </h3>
      <div>
        <div class="row">
          <div *ngIf="errorType === 'validation'">
            <ul>
              <li class="mb-2" *ngFor="let error of orderlineErrors">
                <div>
                  <strong>Orderline {{ error.orderline }}:</strong>
                  <p class="mb-0">{{ error.message }}</p>
                  <p class="mb-0">{{ error.details }}</p>
                  <ul *ngIf="error.conditions.length > 0">
                    <li *ngFor="let condition of error.conditions">
                      {{ condition }}
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div *ngIf="errorType === 'badRequest'">
            <ul class="mb-0">
              <li *ngFor="let violation of parameterViolations">
                <strong> {{ getPathTranslation(violation.path) }} </strong> -
                Message:
                {{ violation.message }}
              </li>
            </ul>
          </div>

          <div
            *ngIf="
              errorType === 'internalServerError' || errorType === 'generic'
            "
          >
            <h5>Dit is de foutmelding, laat het ons weten!</h5>
            <div *ngIf="error.error?.exception">
              <p class="mb-0">{{ error.error?.exception }}</p>
            </div>
            <div *ngIf="error.error?.details">
              <p class="mb-0">{{ error.error?.details }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
