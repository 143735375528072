import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ORDER_HISTORY_SERVICE_URL,
  MANCOS_SERVICE_URL,
} from 'src/app/injection-tokens';
import { OrderDetails } from './order-details';
import { MancoOrderRequest } from './manco-order-request';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NewMancoService {
  constructor(
    @Inject(ORDER_HISTORY_SERVICE_URL) readonly orderHistoryServiceURL: string,
    @Inject(MANCOS_SERVICE_URL) readonly mancoServiceURL: string,
    readonly http: HttpClient
  ) {}

  getOrderDetails(orderNumber: string): Observable<OrderDetails> {
    return this.http
      .get<OrderDetails>(
        this.orderHistoryServiceURL +
          'internal/orderhistory/orderdetails?orderNumber=' +
          orderNumber
      )
      .pipe(
        map((orderDetails: OrderDetails) => {
          if (orderDetails.deliveryAddress) {
            orderDetails.deliveryAddress.country = this.transformCountry(
              orderDetails.deliveryAddress.country
            );
          }
          return orderDetails;
        })
      );
  }

  transformCountry(country: string | undefined): string {
    const countryMap: Record<string, string> = {
      NLD: 'NL',
      DEU: 'DE',
    };
    return country ? countryMap[country] || country : '';
  }

  createMancoOrder(request: MancoOrderRequest): Observable<any> {
    return this.http.post<MancoOrderRequest>(
      this.mancoServiceURL + '/orders',
      request
    );
  }
}
