<dialog #appDialog class="modal-dialog modal-lg p-0 border-0 shadow-lg">
  <div class="modal-content border-0">
    <!-- Modal Header -->
    <div
      class="modal-header py-3 px-4 bg-light text-dark position-relative border-bottom"
    >
      <h5 class="modal-title m-0">
        <span *ngIf="data?.ticketNumber == ''">Nieuw</span>
        <span *ngIf="!(data?.ticketNumber == '')">
          Freshdesk ticket:
          <a
            href="{{
              (data != undefined ? data.ticketNumber : '') | freshdeskTicketLink
            }}"
            target="_blank"
            class="text-decoration-none text-primary"
            >{{ data?.ticketNumber }}</a
          >
        </span>
      </h5>
      <button
        type="button"
        class="btn-close modal-button"
        aria-label="Close"
        (click)="closeDialog('cancel')"
      ></button>
    </div>

    <!-- Modal Body -->
    <div class="modal-body">
      <app-manco-form
        [data]="data"
        (formSubmit)="handleSubmit($event)"
      ></app-manco-form>
    </div>
  </div>
</dialog>
